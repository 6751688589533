import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { SettingNode } from "../../../models/monitor/MonitorDetailResponse.model";
import { DisplayGroupSettingNode } from "../../../models/monitor/MonitorDetail.model";

interface ChartProps {
  socketData: any;
  settingNodes: any;
  setting_node_data_name: string;
  setting_node_data_unit: string;
  height: number | string;
  graph_color?: string;
  initialData: any;
}

const handleArr = (setting: DisplayGroupSettingNode, data: string) => {
  if (data != "0") {
    if (setting.setting_node_data_type == "1") {
      // 1 = Array
      const dataArray = data.split(",");
      const index = setting.setting_node_sequence;
      if (index >= 0 && index < dataArray.length) {
        const inputValue = parseFloat(dataArray[index]);
        if (!isNaN(inputValue)) {
          return inputValue.toFixed(2);
        }
        return dataArray[index];
      } else {
        return "0";
      }
    } else {
      return data;
    }
  }
  return "0";
};

const LineChartComponent: React.FC<ChartProps> = ({
  socketData,
  settingNodes,
  setting_node_data_name,
  setting_node_data_unit,
  height = 200,
  graph_color = "#00b0f0",
  initialData,
}) => {
  const [datachart, setDatachart] = useState<Array<{ name: string;[key: string]: string | number }>>([]);
  const [currentTimestamp, setCurrentTimestamp] = useState(Date.now()); // เวลาปัจจุบัน
  const [startTime, setStartTime] = useState(Date.now()); // เวลาที่ใช้ตรวจสอบ 15 นาที

  // ⏳ อัปเดต `currentTimestamp` ทุกวินาที
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTimestamp(Date.now());
    }, 1000); // อัปเดตทุก 1 วินาที

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // ตั้งค่าข้อมูลเริ่มต้นจาก initialData
    if (initialData && initialData.length > 0) {
      const initialChartData = initialData.map((data: any) => {
        const sensorData = data[settingNodes.floodgate_id]?.[settingNodes.setting_node_data_key];
        const transactionDate = data[settingNodes.floodgate_id]?.floodgate_transaction_createdate;
        if (!sensorData || !transactionDate) return null;

        const transactionTime = new Intl.DateTimeFormat("th-TH", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
          timeZone: "UTC", // บังคับให้ใช้เวลา UTC
        }).format(new Date(transactionDate));

        const sensorValue = parseFloat(handleArr(settingNodes, sensorData));
        return {
          name: transactionTime,
          timestamp: transactionDate,
          [setting_node_data_name]: sensorValue,
        };
      }).filter(Boolean); // กรองค่า null ออก

      initialChartData.sort((a: any, b: any) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      })

      setDatachart(initialChartData);
    }
  }, [initialData, settingNodes, setting_node_data_name]);

  useEffect(() => {
    if (socketData && Object.keys(socketData).length > 0) {
      const sensorData = socketData[settingNodes.floodgate_id]?.[settingNodes.setting_node_data_key];
      const transactionDate = socketData[settingNodes.floodgate_id]?.floodgate_transaction_createdate;
      if (!sensorData || !transactionDate) return;

      const transactionTime = new Date(transactionDate);
      const formattedTime = transactionTime.toLocaleTimeString("th-TH", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });
      const sensorValue = parseFloat(handleArr(settingNodes, sensorData));

      setDatachart((prevDataChart) => {
        const updatedData = [...prevDataChart];

        updatedData[updatedData.length - 1] = {
          name: formattedTime,
          timestamp: transactionDate,
          [setting_node_data_name]: sensorValue,
        };

        if (currentTimestamp - startTime >= 900000) {
          updatedData.push({
            name: formattedTime,
            timestamp: transactionDate,
            [setting_node_data_name]: sensorValue,
          });

          if (updatedData.length > 10) {
            updatedData.shift();
          }

          setStartTime(Date.now());

          return updatedData;
        }

        return updatedData;
      });
    }
  }, [socketData, settingNodes, setting_node_data_name]);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div style={{
          backgroundColor: "white",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          fontSize: "14px",
        }}>
          <p>{`Time: ${payload[0].payload.timestamp}`}</p>
          <p>{`${payload[0].name}: ${payload[0].value} ${setting_node_data_unit}`}</p>
        </div>
      );
    }

    return null;
  };


  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart
        data={datachart}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line
          type="monotone"
          dataKey={setting_node_data_name}
          stroke={graph_color}
          strokeWidth={2}
          dot={{ r: 4 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent;
