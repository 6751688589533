import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from "react-router-dom";
import NodeItem from "./NodeItem";
import { useAppDispatch, useAppSelector } from "../../../../stores/store";
import {
    fetchMonitorDetail,
    monitorDetailSelector,
} from "../../../../stores/slices/MonitorDetailSlice";
import {
    NodetypeInputAndDisplay,
    SettingType,
} from "../../../../models/monitor/MonitorDetail.model";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Grid,
    styled,
    Typography,
} from "@mui/material";
import { io } from "socket.io-client";
import config from "../../../../config";
import { eUserRole } from "../../../../services/Constants/enum/enum.model";
import { userRolesSelector } from "../../../../stores/slices/UserRolesSlice";
import {
    fetchTransationMonitor,
    transationMonitorSelector,
} from "../../../../stores/slices/FloodgateTransationMonitorSlice";
import { MonitorDetailRequest } from "../../../../models/monitor/MonitorDetailRequest.model";
import MonitorTransactionModal from "../MonitorTransaction/MonitorTransactionModal";
import MonitorLogsModal from "../MonitorLogs/MonitorLogsModal";

const TransparentAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: "#EFEFEF",
    borderRadius: "8px",
    boxShadow: "none",
    marginBottom: theme.spacing(2),
}));

const MonitorDetail: React.FC = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const { data: dataMonitorDetail, isLoading, isError, errorMessage } = useAppSelector(monitorDetailSelector);
    const [socketData, setSocketData] = useState<{ [key: string]: { [key: string]: string }; }>({});
    const { data: initial_data, isLoading: init_data_load } = useAppSelector(transationMonitorSelector);
    const userRole = useAppSelector(userRolesSelector);

    const [txnModalOpen, settxnModalOpen] = useState(false);
    const [logsModalOpen, setLogsModalOpen] = useState(false);

    const handleTxnClick = () => {
        settxnModalOpen(true);
    };

    const handleLogsClick = () => {
        setLogsModalOpen(true);
    };

    const lastSentTimestamps: { [key: string]: number } = {};
    useEffect(() => {
        const requestData: MonitorDetailRequest = {
            floodgate_id: id || "",
        };
        dispatch(fetchMonitorDetail(requestData));
        dispatch(fetchTransationMonitor({
            floodgate_id: id || "",
            number_of_rows: "",
            page: "",
            startdate: "",
            enddate: "",
        }));

        const socket = io(config.ws.ws_floodgate_guest_url);
        const shouldSendToQueue = (floodgate_id: string, newTimestamp: number, minIntervalMinutes: number) => {
            const lastSentTimestamp = lastSentTimestamps[floodgate_id];
            if (lastSentTimestamp) {
                const diffInMinutes = (newTimestamp - lastSentTimestamp) / 60000;
                if (diffInMinutes < minIntervalMinutes) {
                    return false;
                }
            }
            lastSentTimestamps[floodgate_id] = newTimestamp;
            return true;
        };

        socket.on("data", (data) => {
            setSocketData((prevData) => {
                if (!txnModalOpen && !logsModalOpen) {
                    const newData = data.reduce((acc: any, item: any) => {
                        acc[item.floodgate_id] = item.floodgate_transaction_details;
                        acc[item.floodgate_id].floodgate_transaction_createdate = item.floodgate_transaction_createdate;
                        return acc;
                    }, {});

                    const shouldUpdate = Object.keys(newData).some((key) => {
                        const newTimestamp = new Date(newData[key].floodgate_transaction_createdate).getTime();
                        return shouldSendToQueue(key, newTimestamp, 1); // กำหนดเป็น 1 นาที
                    });

                    if (shouldUpdate || JSON.stringify(prevData) !== JSON.stringify(newData)) {
                        return newData;
                    }
                }
                return prevData;
            });
        });

        return () => {
            socket.disconnect();
        };
    }, [dispatch, id, txnModalOpen, logsModalOpen]);

    const hasRole = (roles: eUserRole[]): boolean => {
        if (!userRole.data) return false;
        if (Array.isArray(userRole.data)) {
            return userRole.data.some((role) =>
                roles.includes(role.role_account_id as eUserRole)
            );
        }
        return roles.includes(userRole.data.role_account_id as eUserRole);
    };

    const filteredSettingNodes = useMemo(() => {
        if (!dataMonitorDetail?.response.setting_nodes) return [];
        return dataMonitorDetail.response.setting_nodes.filter(
            (node) => hasRole([eUserRole.Operator])
                ? !node.nodetype_input_and_display.some((display) => display.is_control)
                : true
        );
    }, [dataMonitorDetail, userRole]);

    const mapSettingNodes = (settingNodes: any) => {
        const mappedDataNew: { [key: string]: any } = {};

        settingNodes.forEach((node: any) => {
            if (!node?.setting_node_type_communication) { //response
                node.nodetype_input_and_display.forEach((display: NodetypeInputAndDisplay) => {
                    if (!mappedDataNew[display.display_group]) {
                        mappedDataNew[display.display_group] = {
                            floodgate_id: node.floodgate_id,
                            display_group_name: display.display_group, //หัวข้อใหญ่
                            setting_type_group: []
                        };
                    }

                    if (!mappedDataNew[display.display_group].setting_type_group.some((i: { setting_type_id: any; }) => i.setting_type_id === node.setting_type_id)) {
                        mappedDataNew[display.display_group].setting_type_group.push({
                            setting_type_id: node.setting_type_id,
                            setting_type: node.setting_type, //หัวข้อยรอง
                            item: []
                        });
                    }

                    if (!mappedDataNew[display.display_group].setting_type_group.filter(
                        (i: { setting_type_id: any; }) => i.setting_type_id === node.setting_type_id)[0].item.some(
                            (i: { setting_node_data_key: any; }) => i.setting_node_data_key === node.setting_node_data_key)) {

                        mappedDataNew[display.display_group].setting_type_group.filter(
                            (i: { setting_type_id: any; }) => i.setting_type_id === node.setting_type_id)[0].item.push({
                                setting_node_data_key: node.setting_node_data_key,
                                isArrayVariable: Number(node.setting_node_data_type) === 1, //array
                                display_object: [],
                            });
                    }

                    mappedDataNew[display.display_group].setting_type_group.filter(
                        (i: { setting_type_id: any; }) => i.setting_type_id === node.setting_type_id)[0].item.filter(
                            (i: { setting_node_data_key: any; }) => i.setting_node_data_key === node.setting_node_data_key)[0].display_object.push({
                                type_input_and_display_id: display.type_input_and_display_id,
                                setting_node_id: display.setting_node_id,
                                is_control: display.is_control, //***** */
                                is_btn: display.is_btn,
                                btn_value: display.btn_value,
                                btn_name: display.btn_name,
                                btn_color: display.btn_color,
                                is_text_input: display.is_text_input,
                                text_input_name: display.text_input_name,
                                is_monitor: display.is_monitor, //***** */
                                is_status_light: display.is_status_light,
                                status_light_value: display.status_light_value,
                                status_light_name: display.status_light_name,
                                status_light_color: display.status_light_color,
                                is_graph: display.is_graph,
                                graph_color: display.graph_color,
                                is_img: display.is_img,
                                img_group_name: display.img_group_name,
                                display_group: display.display_group,
                                setting_nodes: {
                                    setting_node_id: node.setting_node_id,
                                    setting_type_id: node.setting_type_id,
                                    setting_type: node.setting_type,
                                    floodgate_id: node.floodgate_id,
                                    setting_node_data_display_main: node.setting_node_data_display_main,
                                    setting_node_data_key: node.setting_node_data_key,
                                    setting_node_data_name: node.setting_node_data_name,
                                    setting_node_data_min: node.setting_node_data_min,
                                    setting_node_data_max: node.setting_node_data_max,
                                    setting_node_data_unit: node.setting_node_data_unit,
                                    setting_node_type_communication: node.setting_node_type_communication,
                                    setting_node_data_type: node.setting_node_data_type,
                                    setting_node_sequence: Number(node.setting_node_sequence),
                                    setting_node_discription: node.setting_node_discription,
                                }
                            });
                });
            }
        });

        const sortedMappedDataNew = Object.values(mappedDataNew).sort((a: any, b: any) => {
            if (a.display_group_name < b.display_group_name) return -1;
            if (a.display_group_name > b.display_group_name) return 1;
            return 0;
        }).map((group: any) => {
            group.setting_type_group.sort((a: any, b: any) => {
                if (a.setting_type.setting_type_name < b.setting_type.setting_type_name) return -1;
                if (a.setting_type.setting_type_name > b.setting_type.setting_type_name) return 1;
                return 0;
            }).map((settingType: any) => {
                settingType.item.map((item: any) => {
                    if (item.isArrayVariable) { //array
                        if (item.display_object.every((disobj: any) => disobj.setting_nodes.setting_node_sequence == 0)) {
                            if (item.display_object.every((disobj: any) => disobj.is_control)) {
                                if (item.display_object.every((disobj: any) => disobj.is_text_input)) {
                                    item.display_object.sort((a: any, b: any) => {
                                        if (a.setting_nodes.setting_node_data_name < b.setting_nodes.setting_node_data_name) return -1;
                                        if (a.setting_nodes.setting_node_data_name > b.setting_nodes.setting_node_data_name) return 1;
                                        return 0;
                                    });
                                }
                                if (item.display_object.every((disobj: any) => disobj.is_btn)) {
                                    const sequences = item.display_object.map((disobj: any) => disobj.btn_value);
                                    const hasDuplicates = new Set(sequences).size !== sequences.length;
                                    if (hasDuplicates) {
                                        item.display_object.sort((a: any, b: any) => {
                                            if (a.btn_name < b.btn_name) return -1;
                                            if (a.btn_name > b.btn_name) return 1;
                                            return 0;
                                        });
                                    } else {
                                        item.display_object.sort((a: any, b: any) => {
                                            if (a.btn_value < b.btn_value) return -1;
                                            if (a.btn_value > b.btn_value) return 1;
                                            return 0;
                                        });
                                    }
                                }
                            }
                            if (item.display_object.every((disobj: any) => disobj.is_monitor)) {
                                if (item.display_object.every((disobj: any) => disobj.is_graph)) {
                                    item.display_object.sort((a: any, b: any) => {
                                        if (a.setting_nodes.setting_node_data_name < b.setting_nodes.setting_node_data_name) return -1;
                                        if (a.setting_nodes.setting_node_data_name > b.setting_nodes.setting_node_data_name) return 1;
                                        return 0;
                                    });
                                }
                                if (item.display_object.every((disobj: any) => disobj.is_status_light)) {
                                    const sequences = item.display_object.map((disobj: any) => disobj.status_light_value);
                                    const hasDuplicates = new Set(sequences).size !== sequences.length;
                                    if (hasDuplicates) {
                                        item.display_object.sort((a: any, b: any) => {
                                            if (a.status_light_name < b.status_light_name) return -1;
                                            if (a.status_light_name > b.status_light_name) return 1;
                                            return 0;
                                        });
                                    } else {
                                        item.display_object.sort((a: any, b: any) => {
                                            if (a.status_light_value < b.status_light_value) return -1;
                                            if (a.status_light_value > b.status_light_value) return 1;
                                            return 0;
                                        });
                                    }
                                }
                            }
                        } else {
                            item.display_object.sort((a: any, b: any) => {
                                if (a.setting_nodes.setting_node_sequence < b.setting_nodes.setting_node_sequence) return -1;
                                if (a.setting_nodes.setting_node_sequence > b.setting_nodes.setting_node_sequence) return 1;
                                return 0;
                            });
                        }
                    } else {
                        item.display_object.sort((a: any, b: any) => {
                            if (a.setting_nodes.setting_node_data_name < b.setting_nodes.setting_node_data_name) return -1;
                            if (a.setting_nodes.setting_node_data_name > b.setting_nodes.setting_node_data_name) return 1;
                            return 0;
                        });
                    }
                }).sort((a: any, b: any) => {
                    if (a.display_object[0].setting_nodes.setting_node_data_name < b.display_object[0].setting_nodes.setting_node_data_name) return -1;
                    if (a.display_object[0].setting_nodes.setting_node_data_name > b.display_object[0].setting_nodes.setting_node_data_name) return 1;
                    return 0;
                });
            });
            return group;
        });

        return sortedMappedDataNew;
    };

    const mappedSettingNodes = useMemo(() => {
        if (!filteredSettingNodes) return [];
        return mapSettingNodes(filteredSettingNodes);
    }, [filteredSettingNodes]);

    if (isLoading || init_data_load) {
        return <CircularProgress />;
    }

    if (isError) {
        return <div>Error: {errorMessage}</div>;
    }

    return (
        <>
            <Box sx={{ padding: 2 }}>
                <TransparentAccordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{dataMonitorDetail?.response.floodgate_name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ display: 'flex', flexGrow: 1, mr: '20px', mb: '30px' }}>
                            <Button color="primary" onClick={handleTxnClick}>ตารางข้อมูล transaction</Button>
                            <Button color="primary" onClick={handleLogsClick}>ตารางข้อมูลประวัติการทำรายการ</Button>
                        </Box>
                        <Grid container spacing={2}>
                            {mappedSettingNodes.map((displaygroup, index) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    key={displaygroup.display_group_name}
                                >
                                    <Grid
                                        item
                                        sx={{
                                            backgroundColor: "rgb(0 124 255 / 9%)",
                                            padding: 2,
                                            borderRadius: "0.6rem",
                                        }}
                                    >
                                        {displaygroup.display_group_name}
                                        <Box
                                            component="hr"
                                            sx={{
                                                border: "1px solid #000",
                                                width: "100%",
                                                margin: "20px 0",
                                            }}
                                        />
                                        <NodeItem
                                            floodgateId={displaygroup.floodgate_id}
                                            displaygroup={displaygroup.setting_type_group}
                                            socketData={socketData}
                                            initialData={initial_data}
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </AccordionDetails>
                </TransparentAccordion>
            </Box>
            <MonitorTransactionModal
                floodgateID={id || ""}
                open={txnModalOpen}
                onClose={() => settxnModalOpen(false)}
                dataMonitorDetail={dataMonitorDetail?.response || null}
            />
            <MonitorLogsModal
                floodgateID={id || ""}
                open={logsModalOpen}
                onClose={() => setLogsModalOpen(false)}
            />
        </>
    );
};

export default MonitorDetail;