import { useState } from "react";
import { DisplayGroup, DisplayGroupSettingNode } from "../../../../models/monitor/MonitorDetail.model"
import { Box, Button, Card, CardContent, Grid, Modal, Paper, styled, Typography, useMediaQuery } from "@mui/material";
import LineChartComponent from "../../../layouts/Charts/LineChartComponent";

interface TextAndImgAndGraphItemProps {
    display_group_item: DisplayGroup;
    index: number;
    socketData: { [key: string]: { [key: string]: string } };
    floodgateId: string;
    initialData: Array<{ [key: string]: { [key: string]: string }; }> | null;
}

const checkImageExists = (imagepath: string) => {
    fetch(imagepath, { method: "HEAD" })
        .then((response) => {
            if (response.ok) {
                return imagepath;
            } else {
                return `${process.env.PUBLIC_URL}/images/no-photo-available.png`;
            }
        })
        .catch((error) => {
            return `${process.env.PUBLIC_URL}/images/no-photo-available.png`;
        });
    return `${process.env.PUBLIC_URL}/images/no-photo-available.png`;
};

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: "12px",
    backgroundColor: "rgba(200, 255, 255, 0.2)",
    boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
        transform: "scale(1.05)",
    },
    marginBottom: 10,
}));

const handleArr = (setting: DisplayGroupSettingNode, data: string) => {
    if (data != "0") {
        if (setting.setting_node_data_type == "1") {
            // 1 = Array
            const dataArray = data.split(",");
            const index = setting.setting_node_sequence;
            if (index >= 0 && index < dataArray.length) {
                const inputValue = parseFloat(dataArray[index]);
                if (!isNaN(inputValue)) {
                    return inputValue.toFixed(2);
                }
                return dataArray[index];
            } else {
                return "0";
            }
        } else {
            return data;
        }
    }
    return "0";
};

export default function TextAndImgAndGraphItem({ display_group_item, index, socketData, floodgateId, initialData }: TextAndImgAndGraphItemProps) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const imagepath = checkImageExists(
        `/ProjectImages/${display_group_item.img_group_name}/${parseInt(handleArr(
            display_group_item.setting_nodes,
            socketData[floodgateId]?.[display_group_item.setting_nodes?.setting_node_data_key || ""] || "0"
        ))}.png`
    );

    const isMobile = useMediaQuery("(max-width:600px)");

    return (
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{
            borderRadius: "0.6rem",
            marginBottom: 2,
        }}>
            <Card className="shadow-lg rounded-lg" key={`control-${index}`} sx={{
                textAlign: "center",
                borderRadius: "12px",
                backgroundColor: "rgb(255 255 255)",
                boxShadow: "none",
            }}>
                <CardContent>
                    <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="flex-start">

                        {/************* content */}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ borderRadius: "0.6rem" }}>
                                <>
                                    {
                                        <StyledPaper>
                                            <Typography variant="h6" textAlign="left" gutterBottom>
                                                {display_group_item.setting_nodes.setting_node_data_name}
                                            </Typography>
                                            <Typography variant="h3" textAlign="center" gutterBottom>
                                                {handleArr(
                                                    display_group_item.setting_nodes,
                                                    socketData[floodgateId]?.[display_group_item.setting_nodes.setting_node_data_key || ""] || "0"
                                                )}
                                            </Typography>
                                            <Typography variant="h5" textAlign="center" gutterBottom>
                                                {display_group_item.setting_nodes.setting_node_data_unit}
                                            </Typography>
                                        </StyledPaper>
                                    }
                                    {display_group_item.is_img && (
                                        <StyledPaper>
                                            <Typography
                                                variant="h6"
                                                textAlign="left"
                                                gutterBottom
                                            >
                                                รูปภาพกราฟฟิก :{" "}
                                                {display_group_item.setting_nodes.setting_node_data_name}
                                            </Typography>
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Box
                                                    component="img"
                                                    src={imagepath}
                                                    alt="Image"
                                                    sx={{
                                                        width: "50%",
                                                        height: "auto",
                                                        borderRadius: "8px",
                                                        boxShadow: 3,
                                                    }}
                                                />
                                            </Box>
                                        </StyledPaper>
                                    )}
                                    {display_group_item.is_graph && (
                                        <>
                                            <Grid item xs={12} sx={{
                                                // backgroundColor: '#FFF',
                                                borderRadius: "0.6rem",
                                            }}>
                                                <StyledPaper onClick={handleOpen}>
                                                    <Typography
                                                        variant="h6"
                                                        textAlign="left"
                                                        gutterBottom
                                                    >
                                                        กราฟแสดงค่า : {display_group_item.setting_nodes.setting_node_data_name}
                                                    </Typography>
                                                    <LineChartComponent
                                                        socketData={socketData}
                                                        settingNodes={display_group_item.setting_nodes}
                                                        setting_node_data_name={
                                                            display_group_item.setting_nodes.setting_node_data_name
                                                        }
                                                        setting_node_data_unit={display_group_item.setting_nodes.setting_node_data_unit}
                                                        height={200}
                                                        graph_color={display_group_item.graph_color}
                                                        initialData={initialData}
                                                    />
                                                </StyledPaper>
                                            </Grid>
                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                                aria-labelledby="modal-title"
                                                aria-describedby="modal-description"
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Box sx={{
                                                    width: {
                                                        xs: "90%", // หน้าจอเล็กสุด (มือถือ) ให้ใช้เต็มความกว้าง
                                                        sm: "90%", // หน้าจอ sm (เล็ก) ให้ใช้ 90%
                                                        md: "80%", // หน้าจอขนาดกลางให้ใช้ 80%
                                                        lg: "70%", // หน้าจอขนาดใหญ่ให้ใช้ 70%
                                                    },
                                                    height: {
                                                        xs: "60%", // หน้าจอ xs (มือถือ) ให้สูง 80%
                                                        sm: "70%", // หน้าจอ sm ให้สูง 70%
                                                        md: "90%", // หน้าจอขนาดกลางให้สูง 90%
                                                        lg: "90%", // หน้าจอขนาดใหญ่ให้สูง 90%
                                                    },
                                                    backgroundColor: "white",
                                                    borderRadius: "10px",
                                                    boxShadow: 24,
                                                    padding: 4,
                                                    position: "relative",
                                                    display: "flex", // ใช้ flexbox เพื่อจัด layout ภายใน
                                                    flexDirection: "column", // จัดเรียง items แนวตั้ง
                                                    overflow: "hidden", // ซ่อนเนื้อหาที่ล้น
                                                }}>
                                                    <Typography id="modal-title"
                                                        variant={isMobile ? "h6" : "h4"}
                                                        gutterBottom>
                                                        กราฟแสดงค่า :{" "}
                                                        {display_group_item.setting_nodes.setting_node_data_name}
                                                    </Typography>

                                                    <Box sx={{
                                                        flexGrow: 1, // ให้ LineChartComponent เติบโตเต็มที่ที่เหลืออยู่
                                                        overflow: "auto", // ทำให้ Chart เลื่อนถ้าเกินขนาด
                                                    }}>
                                                        <LineChartComponent
                                                            socketData={socketData}
                                                            settingNodes={display_group_item.setting_nodes}
                                                            setting_node_data_name={
                                                                display_group_item.setting_nodes.setting_node_data_name
                                                            }
                                                            setting_node_data_unit={display_group_item.setting_nodes.setting_node_data_unit}
                                                            height={isMobile ? 200 : "100%"}
                                                            graph_color={
                                                                display_group_item.graph_color
                                                            }
                                                            initialData={initialData} />
                                                    </Box>

                                                    {/* ปุ่มปิด อยู่ที่มุมขวาบน */}
                                                    <Button onClick={handleClose}
                                                        variant="contained"
                                                        sx={{
                                                            position: "absolute",
                                                            top: 16,
                                                            right: 16,
                                                        }}>
                                                        ปิด
                                                    </Button>
                                                </Box>
                                            </Modal>
                                        </>
                                    )}
                                </>
                            </Grid>
                        </Grid>
                        {/************* content */}

                    </Box>
                </CardContent>
            </Card>
        </Grid >
    );
}